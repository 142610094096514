import classNames from 'classnames';
import styled from 'styled-components/macro';

import { IconDefinition } from '@fortawesome/fontawesome-common-types';

import Icon from 'ev-components/Icon';
import { TextStyle } from 'ev-components/Text';
import Tooltip, { TooltipVariant } from 'ev-components/Tooltip';
import { EVColors } from 'ev-theme/styles';

export enum BadgeSize {
  Small = 'small',
  Large = 'large',
  ExtraLarge = 'extraLarge',
}

export enum BadgeColor {
  Blue = 'blue',
  DarkBlue = 'darkBlue',
  Red = 'red',
  Yellow = 'yellow',
  Green = 'green',
  Pink = 'pink',
  Gray = 'gray',
}

export type BadgeProps = {
  className?: string;
  content?: string | number;
  size?: BadgeSize;
  color?: BadgeColor;
  disabled?: boolean;
  filled?: boolean;
  icon?: IconDefinition;
  id: string;
  invisible?: boolean;
  animated?: boolean;
  role?: string;
  tooltip?: string;
};

const Badge = (props: BadgeProps) => {
  const {
    content = '',
    size = BadgeSize.Small,
    color = BadgeColor.Blue,
    filled = false,
    disabled = false,
    icon,
    className = 'badge',
    id,
    invisible,
    animated = true,
    role,
    tooltip = '',
  } = props;

  return (
    <Tooltip
      arrowPlacement="left"
      id="badge-tooltip"
      maxWidth="250px"
      text={tooltip}
      variant={TooltipVariant.Dark}
    >
      <Component
        aria-label={undefined}
        className={classNames(className, size, !disabled && color, {
          disabled,
          filled,
          hasContent: !!content,
          hasIcon: !!icon,
          oneChar: ('' + content).length === 1,
          invisible,
          animated,
        })}
        data-testid={id}
        role={role}
      >
        {icon && <Icon icon={icon} id="badge-icon" />}
        {content}
      </Component>
    </Tooltip>
  );
};

export default Badge;

const Component = styled.span`
  ${TextStyle.SemiBold.Footnote}
  text-align: center;
  border-radius: 8px;
  min-width: 8px;
  min-height: 8px;
  &.large > svg {
    border-radius: 10px;
    min-width: 10px;
    min-height: 10px;
  }
  &.extraLarge > svg {
    min-width: 18px;
    min-height: 18px;
  }
  svg {
    width: 8px !important;
  }
  &.blue {
    color: ${EVColors.cerulean};
    background: ${EVColors.zircon};
  }
  &.darkBlue {
    color: ${EVColors.cobalt};
    background-color: ${EVColors.zircon};
  }
  &.yellow {
    color: ${EVColors.darkBrown};
    background: ${EVColors.sunflower};
  }
  &.red {
    color: ${EVColors.darkRed};
    background: ${EVColors.sakura};
  }
  &.green {
    color: ${EVColors.darkGreen};
    background: ${EVColors.mint};
  }
  &.pink {
    color: ${EVColors.hotPink};
    background: ${EVColors.sakura};
  }
  &.gray {
    color: ${EVColors.mako};
    background: ${EVColors.concrete};
  }
  &.filled {
    color: ${EVColors.white};
    &.blue {
      background: ${EVColors.cerulean};
    }
    &.darkBlue {
      background: ${EVColors.cobalt};
    }
    &.yellow {
      color: ${EVColors.darkBrown};
      background: ${EVColors.sunflower};
    }
    &.red {
      background: ${EVColors.darkRed};
    }
    &.green {
      background: ${EVColors.darkGreen};
    }
    &.pink {
      background: ${EVColors.hotPink};
    }
    &.gray {
      background: ${EVColors.mako};
    }
  }
  &.disabled {
    color: ${EVColors.mercury};
    background: ${EVColors.concrete};
  }
  &.hasContent,
  &.hasIcon {
    min-width: 16px;
    line-height: 16px;
    &.large {
      min-width: 18px;
      line-height: 18px;
    }
  }
  &.hasContent {
    padding: 0 8px;
    svg {
      width: 10px !important;
      padding: 0 4px 0 0;
    }
    &.large {
      ${TextStyle.SemiBold.Description}
    }
  }
  &.oneChar {
    padding: 0;
  }
  &.invisible {
    opacity: 0;
    transform: scale3d(0, 0, 0);
  }
  &.animated {
    transition:
      all 0.5s 0.3s,
      color 0s 0s,
      background-color 0s 0s;
  }
  opacity: 1;
  transform: scale3d(1, 1, 1);
  transform-origin: center;
`;
