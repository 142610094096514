import { EVColors } from 'ev-theme/styles';

export const MuiMenuItem = {
  styleOverrides: {
    root: {
      minWidth: 320,
      transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      '&.Mui-disabled': {
        // Disabling pointer events interferes with submenuing
        pointerEvents: 'all',
        opacity: 1,
        color: EVColors.mercury,
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        '&:not(.ev-multiple)': {
          backgroundColor: EVColors.zircon,
        },
        '&:hover, &.hover, &.Mui-focusVisible': {
          backgroundColor: EVColors.selectedHover,
        },
        '&.ev-multiple': {
          backgroundColor: 'transparent',
          '&:hover, &.hover, &.Mui-focusVisible': {
            backgroundColor: EVColors.alabaster,
          },
        },
      },
      '&.MuiMenuItem-divider': {
        borderBottom: `1px solid ${EVColors.silverBullet}`,
      },
      '&.ev-header': {
        cursor: 'default',
        fontWeight: 600,
        '&:hover, &.Mui-focusVisible': {
          backgroundColor: EVColors.white,
        },
      },
      '&.ev-sticky-header': {
        position: 'sticky',
        top: 0,
        zIndex: 10,
        cursor: 'default',
        fontWeight: 600,
        backgroundColor: EVColors.white,
        '&:hover, &.Mui-focusVisible': {
          backgroundColor: EVColors.white,
        },
      },
      '&.ev-divider.ev-divider': {
        borderBottom: `1px solid ${EVColors.silverBullet}`,
        padding: 0,
        minHeight: 0,
      },
      '&:hover, &.hover, &.Mui-focusVisible': {
        backgroundColor: EVColors.alabaster,
      },
      '&.ev-small': {
        height: 40,
        minHeight: 40,
        fontSize: 12,
        '.MuiFormControlLabel-label': {
          fontSize: 12,
        },
      },
      '&.ev-no-horizontal-padding': {
        paddingLeft: 0,
        paddingRight: 0,
      },
      '&.ev-no-vertical-padding': {
        paddingTop: 0,
        paddingBottom: 0,
      },
      '&.ev-medium': {
        fontSize: 14,
        height: 58,
        '.MuiFormControlLabel-label': {
          fontSize: 14,
        },
      },
      '&.ev-large': {
        fontSize: 16,
        height: 70,
        '.MuiFormControlLabel-label': {
          fontSize: 16,
        },
      },
      '&.ev-extraLarge': {
        fontSize: 16,
        height: 56,
        '.MuiFormControlLabel-label': {
          fontSize: 16,
        },
      },
      '&.ev-auto-height': {
        height: 'auto',
      },
      '&.ev-auto-width': {
        minWidth: 'auto',
      },
      '&.ev-repeat-option': {
        minWidth: 250,
      },
    },
  },
};
