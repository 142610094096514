import { ImgHTMLAttributes } from 'react';
import styled from 'styled-components/macro';

interface HeaderLogoProps extends ImgHTMLAttributes<HTMLImageElement> {
  altText?: string;
}

export const HeaderContainer = styled.header.attrs({ 'data-testid': 'header' })`
  display: flex;
  flex-shrink: 0;
  align-items: center;

  padding: 0 24px;
  height: 75px;
`;

export const HeaderLogo = styled.img.attrs<HeaderLogoProps>(props => ({
  alt: props.altText || 'Logo',
  'data-testid': 'logo',
}))<HeaderLogoProps>`
  margin-right: auto;
  width: 110px;
  max-height: 33px;
`;
