import { DateTime } from 'luxon';
import { useCallback, useState } from 'react';
import styled from 'styled-components/macro';

import {
  DateRange,
  DateRangePicker as MUIDateRange,
  StaticDateRangePicker,
  StaticDateRangePickerProps,
} from '@mui/x-date-pickers-pro';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DateRangeShortcut from 'ev-components/DateRange/DateRangeShortcut';
import Text from 'ev-components/Text';
import { EVColors } from 'ev-theme/styles';
import { dateRangeValidator } from 'ev-utils/validators';

import {
  convertToDateRange,
  convertToDateRangeValue,
  DateRangeShortcutType,
  DateRangeValue,
  getDateRangeShortcutValue,
  useGetDateRangeShortcutLabel,
} from './utils';

export type DateRangePickerProps = {
  value: DateRangeValue;
  onChange: (value: DateRangeValue) => void;
  shortcuts?: DateRangeShortcutType[] | null;
  format?: string;
  readOnly?: boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
  calendars?: 1 | 2 | 3;
  displayStaticWrapperAs?: 'desktop' | 'mobile';
  listItemHeight?: string;
  listItemWidth?: string;
  id?: string;
  localeText?: { start: string; end: string };
  staticPicker?: boolean;
  size?: 'small' | 'medium' | 'large';
};

export const DateRangePicker = ({
  value,
  onChange,
  shortcuts,
  format,
  readOnly = false,
  disableFuture = false,
  disablePast = false,
  calendars = 1,
  displayStaticWrapperAs = 'desktop',
  listItemHeight,
  listItemWidth,
  id,
  localeText,
  staticPicker = true,
  size = 'medium',
}: DateRangePickerProps) => {
  const dateRange = convertToDateRange(value, format);
  const getDateRangeShortcutLabel = useGetDateRangeShortcutLabel();
  const [error, setError] = useState('');

  const handleOnChange = useCallback(
    (range: DateRange<DateTime>) => {
      const dateString = `${range[0]?.toISO() || ''},${range[1]?.toISO() || ''}`;

      const validationResult = dateRangeValidator({
        value: dateString,
      });

      if (!validationResult.valid) {
        setError(validationResult.message || '');
      } else {
        setError('');
        onChange(convertToDateRangeValue(range, format));
      }
    },
    [onChange, format],
  );

  const dateRangeProps: StaticDateRangePickerProps<DateTime> = {
    calendars,
    disableFuture,
    disablePast,
    readOnly,
    value: dateRange,
    displayStaticWrapperAs,
    onAccept: staticPicker ? undefined : handleOnChange,
    onChange: staticPicker ? handleOnChange : undefined,
    defaultRangePosition: 'end',
  };

  if (shortcuts) {
    dateRangeProps.slots = { shortcuts: DateRangeShortcut };
    dateRangeProps.slotProps = {
      shortcuts: {
        value: dateRange,
        items: shortcuts.map(shortcut => ({
          label: getDateRangeShortcutLabel(shortcut),
          getValue: () => getDateRangeShortcutValue(shortcut),
        })),
        listItemHeight,
        listItemWidth,
      } as never,
    };
  }

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      {staticPicker ? (
        <>
          <StaticDateRangePicker
            {...dateRangeProps}
            data-testid={id}
            localeText={localeText}
          />
          {error && (
            <Text.Footnote color={EVColors.darkRed} marginTop="4px">
              {error}
            </Text.Footnote>
          )}
        </>
      ) : (
        <DataRangeContainer>
          <MUIDateRange
            {...dateRangeProps}
            data-testid={id}
            localeText={localeText}
            slotProps={{
              textField: {
                size: size,
              },
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderColor: EVColors.mako,
              },
            }}
          />
          {error && (
            <Text.Footnote color={EVColors.darkRed} marginTop="4px">
              <ErrorIcon icon={faExclamationCircle} />
              {error}
            </Text.Footnote>
          )}
        </DataRangeContainer>
      )}
    </LocalizationProvider>
  );
};

export default DateRangePicker;

const DataRangeContainer = styled.div`
  display: block;
  padding-top: 16px;
`;

const ErrorIcon = styled(FontAwesomeIcon)`
  padding-right: 4px;
`;
