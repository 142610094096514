import _ from 'lodash';
import React, {
  createContext,
  ReactNode,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import Slide from '@mui/material/Slide';

import { faTimes } from '@fortawesome/pro-solid-svg-icons';

import { useTranslation } from 'ev-i18n';

import ActionButtonDeprecated from 'ev-components/ActionButtonDeprecated';
import SnackbarToast from 'ev-components/SnackbarToast/SnackbarToast';
import { ToastVariants } from 'ev-components/Toast';
import { NewBannersEnabled as NewBannersEnabledConfig } from 'ev-config/config';
import { clearGlobalError } from 'ev-store/actions';
import { useAppDispatch, useAppSelector } from 'ev-store/redux';
import { EVColors } from 'ev-theme/styles/Colors';
import { base64Decode } from 'ev-utils/base64';

export type ToastType = 'success' | 'warning';

const INTERVAL_TIMEOUT = 5000;

const ToastContext = createContext<{
  setToast: (
    message: string | ReactNode,
    type?: ToastType,
    id?: string,
  ) => void;
}>({ setToast: _.noop });

const ToastParam = 'toast';

type ToastParamType = {
  message: string | ReactNode;
  type?: ToastType;
  id?: string;
};

const decodeToast = (toastEncoded: string): ToastParamType | null => {
  try {
    const decodedToast = base64Decode(toastEncoded);
    return JSON.parse(decodedToast) as ToastParamType;
  } catch (e: unknown) {
    if (e instanceof Error) {
      console.error(`Unable to display toast - ${e.message}`);
    }
    return null;
  }
};

const ToastInner = ({
  children,
  newBanner,
}: {
  children: React.ReactNode;
  newBanner?: boolean;
}) => {
  const { t } = useTranslation();
  const [toast, setToast] = useState<string | ReactNode>('');
  const [toastId, setToastId] = useState('');
  const [toastType, setToastType] = useState<ToastType>('success');
  const dispatch = useAppDispatch();
  const globalError = useAppSelector(state => state.globalError);

  const intervalRef = useRef<number>();
  const [searchParams, setSearchParams] = useSearchParams();

  const NewBannersEnabled = newBanner ?? NewBannersEnabledConfig;

  const clearToast = () => {
    setToast('');
  };

  const setToastMessage = useCallback(
    (message: string | ReactNode, type: ToastType = 'success', id?: string) => {
      if (globalError) {
        dispatch(clearGlobalError());
      }
      setToast(message);
      if (type !== toastType) {
        setToastType(type);
      }
      setToastId(id ?? 'evisit-toast');
    },
    [globalError, dispatch, toastType],
  );

  useEffect(() => {
    if (toast && !NewBannersEnabled) {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }

      intervalRef.current = window.setTimeout(clearToast, INTERVAL_TIMEOUT);
    }

    return () => {
      if (intervalRef.current && !NewBannersEnabled) {
        clearInterval(intervalRef.current);
      }
    };
  }, [NewBannersEnabled, toast]);

  // TODO: Remove this code once RA is full migrated
  useEffect(() => {
    const toastEncoded = searchParams.get(ToastParam);
    if (toastEncoded) {
      const toastDecoded = decodeToast(toastEncoded);
      searchParams.delete(ToastParam);
      setSearchParams(searchParams, { replace: true });
      if (toastDecoded) {
        setToastMessage(toastDecoded.message, toastDecoded.type);
      }
    }
  }, [searchParams, setSearchParams, setToastMessage, t]);

  const toastOpen = !!toast;

  if (NewBannersEnabled && toastOpen && typeof toast === 'string') {
    return (
      <ToastContext.Provider value={{ setToast: setToastMessage }}>
        <SnackbarToast
          id={toastId}
          message={toast}
          onDismiss={clearToast}
          open={toastOpen}
          variant={
            toastType === 'success'
              ? ToastVariants.positive
              : ToastVariants.negative
          }
        />
        {children}
      </ToastContext.Provider>
    );
  }

  return (
    <ToastContext.Provider value={{ setToast: setToastMessage }}>
      <Slide direction="down" in={toastOpen} mountOnEnter unmountOnExit>
        <StyledToast
          $toastType={toastType}
          data-testid={toastId}
          id={toastId}
          key={toastOpen ? 'toast-open' : 'toast-closed'}
          role="alert"
        >
          <Message>{toast}</Message>
          <ActionButtonDeprecated
            displayLabel={false}
            icon={faTimes}
            iconColor={EVColors.white}
            iconSize="xs"
            id="clearToastMessage"
            label={t('Close')}
            onClick={clearToast}
            padding="0"
          />
        </StyledToast>
      </Slide>
      {children}
    </ToastContext.Provider>
  );
};

export const ToastProvider = ({
  children,
  newBanner,
}: {
  children: React.ReactNode;
  newBanner?: boolean;
}) => {
  return (
    <Suspense fallback={<div />}>
      <ToastInner newBanner={newBanner}>{children}</ToastInner>
    </Suspense>
  );
};

const StyledToast = styled.div<{ $toastType: ToastType }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1500;
  min-height: 64px;
  height: auto;
  background: ${({ $toastType }) =>
    $toastType === 'success'
      ? `${EVColors.mediumGreen}`
      : `${EVColors.sunflower}`};
  color: ${({ $toastType }) =>
    $toastType === 'success' ? `${EVColors.white}` : `${EVColors.black}`};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  box-sizing: border-box;
  padding: 10px 32px;
`;

const Message = styled.div`
  flex-grow: 1;
`;

export default function useToast() {
  const { setToast } = useContext(ToastContext);
  return setToast;
}
