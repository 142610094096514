import { RadioButtonSize } from 'ev-components/RadioButtons/types';
import { EVColors } from 'ev-theme/styles';

declare module '@mui/material/Radio' {
  interface RadioProps {
    evsize?: RadioButtonSize;
  }
}

export const MuiRadio = {
  styleOverrides: {
    root: {
      padding: 0,
      margin: 8,
      borderRadius: '100px',
      border: `2px solid ${EVColors.storm}`,
      color: EVColors.mako,
      '.MuiSvgIcon-root': {
        color: 'transparent',
      },
      '&.is-error': {
        borderColor: EVColors.maraschino,
      },
      '&.Mui-disabled': {
        borderColor: EVColors.aluminum,
      },
      '&.display-focus, &:focus-within': {
        border: `2px solid ${EVColors.border.focusDark}`,
        borderRadius: '100px',
        boxShadow: `0 0 0 4px ${EVColors.border.focusLight}`,
      },
      '&.Mui-checked': {
        borderColor: 'transparent',
        '.MuiSvgIcon-root': {
          color: 'inherit',
        },
      },
      '&.Mui-checked.display-focus': {
        'svg:first-child': {
          border: '2px solid',
          borderRadius: '100px',
          borderColor: EVColors.border.focusDark,
          boxShadow: `0 0 0 1px ${EVColors.border.focusDark}`,
        },
      },
      '&.Mui-checked.Mui-disabled': {
        borderColor: 'transparent',
        color: EVColors.aluminum,
      },
    },
  },
  variants: [
    {
      props: {
        evsize: RadioButtonSize.Small,
      },
      style: {
        height: 16,
        width: 16,
        margin: '4px 8px',
        '.MuiSvgIcon-root': {
          height: 16,
          width: 16,
        },
        '&.Mui-checked': {
          '.MuiSvgIcon-root': {
            height: 20,
            width: 20,
          },
        },
      },
    },
    {
      props: {
        evsize: RadioButtonSize.Medium,
      },
      style: {
        height: 18,
        width: 18,
        margin: 8,
        svg: {
          height: 18,
          width: 18,
        },
        '&.Mui-checked': {
          '.MuiSvgIcon-root': {
            height: 26,
            width: 26,
          },
        },
      },
    },
    {
      props: {
        evsize: RadioButtonSize.Large,
      },
      style: {
        height: 22,
        width: 22,
        margin: '16px 8px',
        svg: {
          height: 22,
          width: 22,
        },
        '&.Mui-checked': {
          '.MuiSvgIcon-root': {
            height: 28,
            width: 28,
          },
        },
      },
    },
    {
      props: {
        evsize: RadioButtonSize.ExtraLarge,
      },
      style: {
        height: 24,
        width: 24,
        margin: '20px 8px',
        svg: {
          height: 24,
          width: 24,
        },
        '&.Mui-checked': {
          '.MuiSvgIcon-root': {
            height: 30,
            width: 30,
          },
        },
      },
    },
  ],
};
