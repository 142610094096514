import { useCallback, useEffect } from 'react';
import styled from 'styled-components/macro';

import { useTranslation } from 'ev-i18n';

import BaseButton from 'ev-components/Button';
import { usePrimaryUser } from 'ev-hooks/commonData';
import { setTestModeOverlayVisible } from 'ev-store/actions';
import { useAppDispatch, useAppSelector } from 'ev-store/redux';
import { EVColors } from 'ev-theme/styles';
import { SessionStorage } from 'ev-utils/storage';

export const TestModeStorageKey = '_eVisitTestModeHidden';

const TestModeIndicator = () => {
  const primaryUser = usePrimaryUser();
  const { t } = useTranslation();

  const testModeOverlayVisible = useAppSelector(
    ({ testModeOverlayVisible }) => testModeOverlayVisible,
  );

  const dispatch = useAppDispatch();

  const hideOverlay = useCallback(() => {
    SessionStorage.setItem(TestModeStorageKey, 'true');
    dispatch(setTestModeOverlayVisible(false));
  }, [dispatch]);

  useEffect(() => {
    const hidden = SessionStorage.getItem(TestModeStorageKey) === 'true';

    if (hidden) {
      hideOverlay();
    }
  }, [hideOverlay]);

  if (!testModeOverlayVisible) {
    return null;
  }

  if (primaryUser.attributes.test_mode) {
    return (
      <span data-testid="test-mode" role="complementary">
        <Overlay />
        <Button id="test-mode-button" onClick={hideOverlay}>
          {t('Test Mode')}
        </Button>
      </span>
    );
  }
  return null;
};

export default TestModeIndicator;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  border: 2px solid ${EVColors.maraschino};
  pointer-events: none;
  z-index: 4;

  display: flex;
`;

const Button = styled(BaseButton)`
  background: ${EVColors.maraschino};
  border: none;
  border-radius: 0 0 4px 4px;
  padding: 3px 32px;
  cursor: pointer;
  color: white;
  position: fixed;
  top: 0;
  height: 18px;
  left: calc(50% - 62.5px);
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 10px;
  z-index: 1;

  &:hover {
    background: red;
    border: none;
  }
`;
