import React from 'react';
// Importing module directly due to dependency tree error when bootstrapping
// eslint-disable-next-line no-restricted-imports
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import type {
  IconDefinition,
  IconName,
  SizeProp,
  Transform,
} from '@fortawesome/fontawesome-common-types';
import { faPhoneVolume } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button, { ButtonProps, ButtonTypes } from './Button';

export type IconProps = {
  ariaLabel?: string;
  icon: IconDefinition | React.ReactNode;
  margin?: string;
  id?: string;
  spin?: boolean;
  className?: string;
  transform?: string | Transform;
  size?: SizeProp;
  color?: string;
  buttonBehavior?: {
    id: string;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    label?: string;
  };
  buttonProps?: Partial<ButtonProps>;
  fixedWidth?: boolean;
  width?: string;
  height?: string;
};

const Icon = (props: IconProps) => {
  const { t } = useTranslation();

  const {
    ariaLabel,
    icon,
    id,
    margin,
    className,
    size,
    width,
    height,
    color,
    buttonBehavior,
    spin = false,
    transform,
    buttonProps,
    fixedWidth = true,
  } = props;

  if (!props.icon.prefix) {
    return (
      <StyledCustomIcon $height={height} $margin={margin} $width={width}>
        <props.icon />
      </StyledCustomIcon>
    );
  }

  let rotate = 0;
  if (icon === faPhoneVolume) {
    rotate = -45;
  }

  const ALERT_ICONS: Record<IconName, string> = {
    'circle-exclamation': t('Error'),
    'triangle-exclamation': t('Warning'),
  } as const;

  const label = ALERT_ICONS[icon.iconName] || undefined;

  const iconComponent = (
    <StyledIcon
      $height={height}
      $margin={margin}
      $width={width}
      aria-label={label || ariaLabel}
      className={className}
      color={color}
      data-testid={id}
      fixedWidth={fixedWidth}
      icon={icon}
      size={size}
      spin={spin}
      title={label}
      transform={transform || { rotate }}
    />
  );

  if (buttonBehavior) {
    const { id, onClick } = buttonBehavior;
    return (
      <Button
        id={id}
        label={`button-${id}`}
        minWidth="0"
        onClick={onClick}
        variant={ButtonTypes.Tertiary}
        {...buttonProps}
      >
        {iconComponent}
      </Button>
    );
  }

  return iconComponent;
};

export default Icon;

const StyledIcon = styled(FontAwesomeIcon)<{
  $margin?: string;
  $height?: string;
  $width?: string;
}>`
  ${p => p.$margin && `margin: ${p.$margin} !important`};
  ${p => p.$width && `width: ${p.$width}`};
  ${p => p.$height && `height: ${p.$height}`};
`;

const StyledCustomIcon = styled.span<{
  $margin?: string;
  $height?: string;
  $width?: string;
}>`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  svg {
    ${p => p.$margin && `margin: ${p.$margin} !important`};
    ${p => p.$width && `width: ${p.$width}`};
    ${p => p.$height && `height: ${p.$height}`};
  }
`;
