import { Qtc } from 'ev-types';

import { sanitizeAndTransformResponseData } from 'ev-api/common/transformers';

import {
  GetDashboardResponse,
  GetProvidersAndSlotGroupsResponse,
} from './responses';

export const getDashboardTransform = (
  response: GetDashboardResponse,
): GetDashboardResponse => {
  const { first_name, last_name } = response.examinee;

  return sanitizeAndTransformResponseData({
    ...response,
    examinee: {
      ...response.examinee,
      name: `${first_name} ${last_name}`,
    },
  });
};

export const getProvidersAndSlotGroupsTransform = (
  response: GetProvidersAndSlotGroupsResponse,
): GetProvidersAndSlotGroupsResponse => {
  const { providers, slot_groups } = response;

  const slotGroupsByProvider = new Map<string, Qtc.Slot[]>();

  Object.keys(slot_groups).forEach(provider_id => {
    slotGroupsByProvider.set(provider_id, slot_groups[provider_id].flat());
  });

  providers.forEach(provider => {
    provider.available_slots = (
      slotGroupsByProvider.get('' + provider.id) || []
    )
      .filter(
        (slot, index, self) => self.findIndex(s => s.id === slot.id) === index,
      )
      .filter(slot => slot.available);
  });

  return sanitizeAndTransformResponseData(response);
};
