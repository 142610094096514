import { EVColors, Font, Typography } from 'ev-theme/styles';

declare module '@mui/material/ToggleButton' {
  interface ToggleButtonPropsSizeOverrides {
    extraLarge: true;
  }
}

export const MuiToggleButton = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      color: EVColors.mako,
      fontSize: 12,
      lineHeight: `16px`,
      border: `1px solid ${EVColors.silverBullet}`,
      textTransform: 'none' as const,
      '&:hover, &.hover, &.ev-display-hover': {
        color: EVColors.gunpowder,
        '& .MuiChip-toggleBadge': {
          backgroundColor: EVColors.alabaster,
          color: EVColors.white,
        },
      },
      '&:focus-within, &.ev-display-focus': {
        boxShadow: `0 0 0 4px ${EVColors.cornflower}`,
        zIndex: 1,
      },
      '&.Mui-selected': {
        border: `1px solid ${EVColors.cerulean}`,
        color: EVColors.cerulean,
        '& .MuiChip-toggleBadge': {
          backgroundColor: EVColors.cerulean,
          color: EVColors.white,
        },
      },
      '&.Mui-disabled': {
        '& .MuiChip-toggleStatus': {
          backgroundColor: EVColors.mercury,
        },
      },
    },
    sizeSmall: {
      height: '32px',
      padding: '8px 16px',
      ...Font.SemiBold,
      ...Typography.Footnote,
      '& .ev-button-icon': {
        fontSize: '12px !important',
      },
    },
    sizeMedium: {
      height: '40px',
      padding: '10px 16px',
      ...Font.SemiBold,
      ...Typography.Description,
      '& .ev-button-icon': {
        fontSize: '14px !important',
      },
    },
    sizeLarge: {
      height: '48px',
      padding: '14px 24px',
      ...Font.SemiBold,
      ...Typography.Body,
      '& .ev-button-icon': {
        fontSize: '16px !important',
      },
    },
    sizeExtraLarge: {
      height: '56px',
      padding: '16px 24px',
      ...Font.SemiBold,
      ...Typography.Body,
      '& .ev-button-icon': {
        fontSize: '16px !important',
      },
    },
  },
};
