enum Environments {
  Local = 'development',
  Test = 'test',
  Poc = 'poc',
  DevTest = 'devtest',
  Acceptance = 'acceptance',
  Beta = 'beta',
  Production = 'production',
}

export const BSHDomainNames = {
  [Environments.Local]: 'bluestreamhealth',
  [Environments.Test]: 'bluestreamhealth',
  [Environments.Poc]: 'bluestreamhealth',
  [Environments.DevTest]: 'bsh-dt',
  [Environments.Acceptance]: 'bsh-acc',
  [Environments.Beta]: 'bsh-beta',
  [Environments.Production]: 'bluestreamhealth',
};

export default Environments;
