import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import { CustomFormField } from 'ev-api/core/custom-forms';

type Description = string | null;

const customFormInitialState = {
  title: '',
  description: null as Description,
  fields: {} as Record<string, CustomFormField | undefined>,
};

export const customFormSlice = createSlice({
  name: 'customForm',
  initialState: customFormInitialState,
  reducers: {
    setFormFieldAttributes(
      state,
      action: PayloadAction<{ field: Partial<CustomFormField> }>,
    ) {
      const { field } = action.payload;
      const { edata_definition_id } = field;
      if (!edata_definition_id) {
        return;
      }
      if (_.isEmpty(state.fields[edata_definition_id])) {
        state.fields[edata_definition_id] = {} as CustomFormField;
      }
      state.fields[edata_definition_id] = {
        ...state.fields[edata_definition_id],
        ...field,
      };
    },
    clearFormFields(state) {
      return {
        ...state,
        fields: customFormInitialState.fields,
      };
    },
    refreshFormFields(
      state,
      action: PayloadAction<{ fields: CustomFormField[] }>,
    ) {
      const updatedFields = action.payload.fields.reduce(
        (acc, field) => {
          acc[field.edata_definition_id] = field;
          return acc;
        },
        {} as Record<string, CustomFormField | undefined>,
      );

      return {
        ...state,
        fields: updatedFields,
      };
    },
    updateDatasetOption(
      state,
      action: PayloadAction<{
        definitionId: string;
        key: string;
        value: string;
      }>,
    ) {
      const { definitionId, key, value } = action.payload;
      if (!state.fields[definitionId]) {
        state.fields[definitionId] = {
          custom_form_id: '',
          edata_definition_id: definitionId,
          label: '',
          description: null,
          is_required: false,
          is_visible: true,
          config_options: {},
          dataset: {},
        } as CustomFormField;
      }
      if (!state.fields[definitionId].dataset) {
        state.fields[definitionId].dataset = {};
      }
      state.fields[definitionId].dataset = {
        ...state.fields[definitionId].dataset,
        [key]: value,
      };
    },
    setFormTitle(state, action: PayloadAction<string>) {
      state.title = action.payload;
    },
    setFormDescription(state, action: PayloadAction<string>) {
      state.description = action.payload;
    },
    clearFormState() {
      return customFormInitialState;
    },
  },
});
