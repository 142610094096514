import { EVColors } from 'ev-theme/styles';

export const MuiListItem = {
  styleOverrides: {
    root: {
      height: '3.25rem',
      '&:hover, &.hover, &.Mui-focusVisible': {
        backgroundColor: EVColors.alabaster,
      },
      '&.Mui-selected': {
        '&:not(.ev-multiple)': {
          backgroundColor: EVColors.zircon,
        },
        '&:hover, &.hover, &.Mui-focusVisible': {
          backgroundColor: EVColors.selectedHover,
        },
      },
      '&.ev-header': {
        flexDirection: 'column',
        alignContent: 'flex-start',
        alignItems: 'flex-start',
        height: '3.5em',

        '.MuiDivider-root': {
          width: '100%',
        },
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      '&.ev-divider': {
        justifyContent: 'center',
        height: '1px',
        marginTop: '0.5rem',
        marginBottom: '0.5rem',
        padding: '0 1rem',
      },
      '&.ev-small': {
        fontSize: 12,
        '.MuiListItemText-primary': {
          fontSize: 12,
        },
      },
      '&.ev-medium': {
        fontSize: 14,
        '.MuiListItemText-primary': {
          fontSize: 14,
        },
      },
      '&.ev-large': {
        fontSize: 16,
        '.MuiListItemText-primary': {
          fontSize: 16,
        },
      },
    },
  },
};
