import { Qtc } from 'ev-types';

import api, { Base, Tags } from 'ev-api/api';

import {
  CancelAppointmentParams,
  CreateAppointmentParams,
  GetDashboardParams,
  GetProvidersAndSlotGroupsParams,
  UpdateAppointmentParams,
} from './params';
import {
  GetDashboardResponse,
  GetHolidaysResponse,
  GetProvidersAndSlotGroupsResponse,
} from './responses';
import {
  getDashboardTransform,
  getProvidersAndSlotGroupsTransform,
} from './transformers';

export const qtcApi = api.injectEndpoints({
  endpoints: builder => ({
    getDashboard: builder.query<GetDashboardResponse, GetDashboardParams>({
      query: ({ caseId, token }) => ({
        url: `${Base.Sch}/appointments/dashboard`,
        params: { case_id: caseId, sec_token: token || undefined },
      }),
      transformResponse: getDashboardTransform,
      providesTags: [Tags.Qtc],
    }),
    getProvidersAndSlotGroups: builder.query<
      GetProvidersAndSlotGroupsResponse,
      GetProvidersAndSlotGroupsParams
    >({
      query: ({
        gender,
        name,
        state,
        latitude,
        longitude,
        radius,
        startDate,
        endDate,
        appointmentTypeId,
        duration,
      }) => ({
        url: `${Base.Sch}/providers_and_slot_groups`,
        params: {
          gender: gender === Qtc.Gender.NoPreference ? undefined : gender,
          name,
          state_code: state,
          latitude,
          longitude,
          from_datetime: startDate,
          to_datetime: endDate,
          appt_type_id: appointmentTypeId,
          radius,
          total_duration: duration,
        },
      }),
      transformResponse: getProvidersAndSlotGroupsTransform,
      providesTags: [Tags.Qtc],
    }),
    getHolidays: builder.query<GetHolidaysResponse, void>({
      query: () => ({
        url: `${Base.Sch}/cases/federal_holidays`,
      }),
    }),
    createAppointment: builder.mutation<void, CreateAppointmentParams>({
      query: ({
        caseId,
        appointmentTypeId,
        providerId,
        slotIds,
        startDateTime,
        endDateTime,
        procedures,
        metadata,
      }) => ({
        url: `${Base.Sch}/appointments`,
        method: 'POST',
        params: {
          case_id: caseId,
          appt_id: appointmentTypeId,
          provider_id: providerId,
          slots: slotIds,
          start_datetime: startDateTime,
          end_datetime: endDateTime,
          procedures,
          metadata,
        },
      }),
      invalidatesTags: [Tags.Qtc],
    }),
    cancelAppointment: builder.mutation<void, CancelAppointmentParams>({
      query: ({ id }) => ({
        url: `${Base.Sch}/appointments/${id}/cancel`,
        method: 'PUT',
      }),
      invalidatesTags: [Tags.Qtc],
    }),
    updateAppointment: builder.mutation<void, UpdateAppointmentParams>({
      query: ({
        id,
        providerId,
        slotIds,
        startDateTime,
        endDateTime,
        metadata,
      }) => ({
        url: `${Base.Sch}/appointments/${id}`,
        method: 'PATCH',
        params: {
          provider_id: providerId,
          slots: slotIds,
          start_datetime: startDateTime,
          end_datetime: endDateTime,
          metadata,
        },
      }),
      invalidatesTags: [Tags.Qtc],
    }),
  }),
});

export const {
  useGetDashboardQuery,
  useGetProvidersAndSlotGroupsQuery,
  useGetHolidaysQuery,
  useCreateAppointmentMutation,
  useCancelAppointmentMutation,
  useUpdateAppointmentMutation,
} = qtcApi;
