import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SidebarPage, SideBarQuickLaunch } from 'ev-store/types';

type SidebarState = {
  permittedTabs: SidebarPage[];
  sidebarPage: SidebarPage;
  open: boolean;
  overlay: boolean;
  quickLaunch: SideBarQuickLaunch[];
};

const initialState: SidebarState = {
  permittedTabs: [],
  sidebarPage: SidebarPage.Visit,
  open: false,
  overlay: false,
  quickLaunch: [],
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setSidebarPage(state, action: PayloadAction<SidebarPage>) {
      state.sidebarPage = action.payload;
    },
    openSidebar(
      state,
      action: PayloadAction<{
        page: SidebarPage;
        overlay?: boolean;
        tabs: SidebarPage[];
        quickLaunch?: SideBarQuickLaunch[];
      }>,
    ) {
      state.sidebarPage = action.payload.page;
      state.open = true;
      state.overlay = !!action.payload.overlay;
      state.permittedTabs = action.payload.tabs;
      state.quickLaunch = action.payload.quickLaunch
        ? action.payload.quickLaunch
        : [];
    },
    openQtcSidebar(state) {
      state.open = true;
    },
    closeSidebar(state) {
      state.permittedTabs = [];
      state.quickLaunch = [];
      state.open = false;
    },
  },
});
