import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SelectedRole = {
  roleId: string;
  name: string;
} | null;
const selectedRoleInitialState = null as SelectedRole;

export const selectedRoleSlice = createSlice({
  name: 'selectedRole',
  initialState: selectedRoleInitialState,
  reducers: {
    selectRole(state, action: PayloadAction<SelectedRole>) {
      return action.payload;
    },
    clearSelectedRole() {
      return null;
    },
  },
});
